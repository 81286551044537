<template>
  <div>
    <ys-infinite-scroll-view v-if="cookeryBook" class="page margin-horizontal-large" style="width: calc(100vw - 168px);">
      <div class="font-size-medium padding-vertical">
        <span class="font-color-primary btn-back" @click="$router.back()">食谱列表</span>
        <span>/餐单编辑</span>
      </div>
      <div class="cookery-book-name font-size-large padding"
           @click="clickEditCookeryBook">
        <span class="font-weight-bold">{{ cookeryBook.name }}</span>
        <span v-if="weekNum != null" class="font-weight-bold"> · 第{{ weekNum + 1 }}周餐单</span>
        <span class="fas fa-pen-alt font-color-danger icon-edit font-size-medium"></span>
      </div>
      <div></div>
      <div class="cookery-book-name padding font-color-secondary font-size-medium" v-if="cookeryBook.message"
           @click="clickEditCookeryBook">
        <span class="font-color-placeholder fas fa-quote-right"></span>
        <span class="margin-left-small">{{ cookeryBook.message }}</span>
        <span class="fas fa-pen-alt icon-edit"></span>
      </div>
      <div class="weekly-record-list-container" ref="weeklyRecordListContainer" @wheel="handleMouseWheel">
        <div class="weekly-record-list layout-horizontal layout-wrap margin-top-large">
          <diet-weekly-record
              v-for="weeklyRecord in dietWeeklyRecordList"
              :key="weeklyRecord.id"
              @click.native="clickWeeklyRecord(weeklyRecord.weekNum)"
              :is-active="weekNum === weeklyRecord.weekNum"
              :value="weeklyRecord"
          ></diet-weekly-record>
        </div>
      </div>
      <diet-list-panel
          class="margin-top"
          :organization-id="organizationId"
          :cookery-book="cookeryBook"
          :week-num="weekNum"
          @input="handleDietInput"
      >
      </diet-list-panel>
      <cookery-book-edit-dialog
          :visible.sync="cookeryBookEditDialogVisible"
          :cookery-book="cookeryBook"
          @confirm="updateCookeryBook"
      ></cookery-book-edit-dialog>
    </ys-infinite-scroll-view>
  </div>
</template>

<script>
import DietListPanel from "@/pages/cookerybook/component/DietListPanel";
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import {CookeryBookUtils} from "@/assets/javascript/cookery-book-utils";
import DietWeeklyRecord from "@/pages/cookerybook/component/DietWeeklyRecord";
import CookeryBookEditDialog from "@/pages/cookerybook/component/CookeryBookEditDialog";

export default {
  name: "CookeryBookDietList",
  mixins: [httpapi],
  components: {CookeryBookEditDialog, DietWeeklyRecord, YsInfiniteScrollView, DietListPanel},
  props: {
    organizationId: Number
  },
  data() {
    return {
      cookeryBookId: null,

      cookeryBook: null,

      weekNum: 0,

      dietWeeklyRecordList: [],

      loadCookeryTimeoutId: null,

      cookeryBookEditDialogVisible: false,
    }
  },
  watch: {
    cookeryBookId() {
      this.loadingCode++;
    }
  },
  activated() {
    this.cookeryBookId = this.$route.query.id;
    this.weekNum = this.$route.query.weekNum;
    this.cookeryBook = null;

    if (this.cookeryBookId) {
      this.loadCookeryBook(this.cookeryBookId);
    } else {
      let cookeryBook = CookeryBookUtils.defaultCookeryBook();
      cookeryBook.organizationId = this.organizationId;
      this.cookeryBook = cookeryBook;
      this.dietWeeklyRecordList = [CookeryBookUtils.defaultDiet(0)];
      this.weekNum = 0;

    }
  },
  methods: {
    clickEditCookeryBook() {
      this.cookeryBookEditDialogVisible = true;
    },
    clickWeeklyRecord(weekNum) {
      this.weekNum = weekNum;
    },
    handleDietInput(cookeryBook) {
      if (this.loadCookeryTimeoutId) {
        clearTimeout(this.loadCookeryTimeoutId);
      }

      this.cookeryBook.id = cookeryBook.id;
      this.loadCookeryTimeoutId = setTimeout(() => {
        this.loadCookeryBook(this.cookeryBook.id);
        this.loadCookeryTimeoutId = null;
      }, 500);
    },
    handleMouseWheel(event) {
      let weeklyRecordListContainer = this.$refs.weeklyRecordListContainer;
      weeklyRecordListContainer.scrollLeft += event.deltaY;
    },
    loadCookeryBook(id) {
      this.$reqGet({
        path: `/cookery_book/${id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            if (!this.cookeryBook) {
              this.cookeryBook = res.data;
            }
            this.dietWeeklyRecordList = res.data.dietWeeklyRecordList;
            if (this.weekNum == null) {
              this.weekNum = this.dietWeeklyRecordList.length > 0 ? this.dietWeeklyRecordList[this.dietWeeklyRecordList.length - 1].weekNum : 0;
              setTimeout(()=>{
                this.$refs.weeklyRecordListContainer.scrollLeft = 99999;
              }, 50);
            }
            this.dietWeeklyRecordList.push(CookeryBookUtils.defaultDiet(this.dietWeeklyRecordList.length));
          })
          .catch(() => this.$message.error('加载失败'));
    },
    updateCookeryBook(cookeryBook) {
      this.cookeryBook = cookeryBook;
      this.dietWeeklyRecordList = cookeryBook.dietWeeklyRecordList;
      if (this.weekNum == null) {
        this.weekNum = this.dietWeeklyRecordList.length > 0 ? this.dietWeeklyRecordList[this.dietWeeklyRecordList.length - 1].weekNum : 0;
      }
      this.dietWeeklyRecordList.push(CookeryBookUtils.defaultDiet(this.dietWeeklyRecordList.length));
    }
  }
}
</script>

<style scoped>

.page {
  height: 100vh;
}

.btn-back {
  cursor: pointer;
}

.cookery-book-name {
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
}

.cookery-book-name .icon-edit {
  visibility: hidden;
}

.cookery-book-name:hover {
  background-color: #f1f1f1;
}

.cookery-book-name:hover .icon-edit {
  visibility: visible;
}


.message {
  padding: 16px;
  background-color: #f1f1f1;
  border-radius: 8px;
  max-width: 600px;
}

.weekly-record {
  margin: 4px;
  background-color: #f1f1f1;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
}

.weekly-record.is-active {
  font-weight: bold;
  box-shadow: 0 0 4px #f091a6;
}

.weekly-record:hover {
  opacity: .7;
}

.weekly-record > div {
  display: inline-block;
  vertical-align: top;
}

.weekly-record .weekly-record-name {
  padding: 8px 16px;
  position: relative;
  z-index: 1;
}

.progress {
  background-color: #f6bbc4;
  width: 100%;
  margin-left: -100%;
  height: 100%;
  position: relative;
  z-index: 0;
}


.weekly-record-list-container {
  overflow-x: scroll;
}

.weekly-record-list-container::-webkit-scrollbar {
  height: 6px;
}

.weekly-record-list-container::-webkit-scrollbar-thumb {
  height: 6px;
  border-radius: 100px;
  background-color: #409EFF;
  visibility: hidden;
}

.weekly-record-list-container:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}


.weekly-record-list {
  width: max-content;
}

</style>